import React from "react";
import "./App.css";
import Logo from "./Logo/Logo";
import Content from "./Content/Content";
import Links from "./Links/Links";

function App() {
  return (
    <div className="container">
      <div className="left-section">
        <Logo />
      </div>
      <div className="right-section">
        <Content />
      </div>
      <div className="links">
        <Links />
      </div>
    </div>
  );
}

export default App;
