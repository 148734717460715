import React, { FunctionComponent } from "react";
import "./Links.css";

const Links: FunctionComponent = () => {
  return (
    <div className="social-links">
      <a
        href="https://github.com/fargusplumdoodle"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/assets/img/github_icon.svg" alt="GitHub" />
      </a>
      <a
        href="https://www.linkedin.com/in/isaac-thiessen/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/assets/img/linkedin_icon.svg" alt="LinkedIn" />
      </a>
    </div>
  );
};

export default Links;
