import React, { FunctionComponent } from "react";
import "./Content.css";
import Links from "../Links/Links";

const Content: FunctionComponent = () => {
  return (
    <div className="content-container">
      <div className="name-title">
        <p className="name">Isaac Thiessen</p>
        <p className="title">Senior Software Developer</p>
      </div>
      <div className="skills">
      Full Stack Technical Leader
      </div>
      <div className="content-links">
        <Links />
      </div>
    </div>
  );
};

export default Content;
