import React, { FunctionComponent, useState, useEffect } from "react";
import "./Logo.css";

const Logo: FunctionComponent = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      const x = (e.clientX / window.innerWidth - 0.5) * 10;
      const y = (e.clientY / window.innerHeight - 0.5) * 10;
      setPosition({ x, y });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div>
      <img
        src="/assets/img/marble_ocean.svg"
        alt="Marble Ocean"
        className="marble-ocean"
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
      />
      <div className="gradient-overlay"></div>
      <img
        src="/assets/img/text_logo.svg"
        alt="OpenArk Logo"
        className="text-logo"
      />
    </div>
  );
};

export default Logo;
